import React from 'react';
import Navbar from './components/Navbar';
import About from './components/About';
import Internships from './components/Internships';
import Projects from './components/Projects';
import Skills from './components/Skills';
import Coursework from './components/Coursework';
import Contact from './components/Contact';

function App() {
  return (
    <div className="bg-gray-900 text-gray-200">
      <Navbar />
      <About />
      <Internships />
      <Projects />
      <Skills />
      <Coursework />
      <Contact />
    </div>
  );
}

export default App;
