import React from 'react';

const SkillCard = ({ title, skills }) => (
  <div className="bg-gradient-to-br from-blue-900 to-black rounded-lg shadow-lg overflow-hidden transform transition duration-300 hover:scale-105 p-6">
    <h3 className="text-2xl font-bold text-blue-300 mb-4">{title}</h3>
    <p className="text-lg text-gray-300">
      {skills.join(' | ')}
    </p>
  </div>
);

export default function Skills() {
  const skillSets = [
    {
      title: "Programming Languages and Libraries",
      skills: ["C++", "C", "JavaScript", "TypeScript", "Python", "Pandas", "NumPy", "PyTorch", "PySpark", "Scikit-learn"]
    },
    {
      title: "Web Development and Frameworks",
      skills: ["React.js", "Next.js", "Axios", "Zod", "JWT", "NextAuth", "Hono", "Express", "Django", "Flask", "Node.js", "Prisma"]
    },
    {
      title: "Tools and Software",
      skills: ["AWS", "Docker", "Kubernetes", "MongoDB", "SQL", "Redis", "Kafka", "Nginx", "Cloudflare", "Git", "GitHub", "CI/CD", "Vitest", "Monorepos"]
    }
  ];

  return (
    <section id="skills" className="bg-gradient-to-b from-black to-blue-900 min-h-screen flex flex-col justify-center items-center text-center px-4 py-20">
      <h2 className="text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-indigo-400 mb-12">
        Skills
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl">
        {skillSets.map((skillSet, index) => (
          <SkillCard key={index} {...skillSet} />
        ))}
      </div>
    </section>
  );
}