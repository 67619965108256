import React from 'react';

const InternshipCard = ({ title, company, date, professor, details, githubLink }) => (
  <div className="bg-gradient-to-br from-blue-900 to-black rounded-lg shadow-lg overflow-hidden transform transition duration-300 hover:scale-105">
    <div className="p-6">
      <h3 className="text-2xl font-bold text-blue-300 mb-2">{title}</h3>
      <p className="italic text-blue-400 mb-1">{company}</p>
      <p className="text-indigo-300 mb-2">{date}</p>
      {professor && <p className="text-blue-400 mb-4">{professor}</p>}
      <ul className="list-disc list-inside text-gray-300 space-y-2 mb-4">
        {details.map((detail, index) => (
          <li key={index}>{detail}</li>
        ))}
      </ul>
    </div>
    <div className="bg-blue-800 px-6 py-4">
      <a
        href={githubLink}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-200 hover:text-blue-100 transition duration-300"
      >
        View on GitHub
      </a>
    </div>
  </div>
);

export default function Internships() {
  const internships = [
    {
      title: "SDE Intern | Attendance System | VehicleRoute Optimization",
      company: "National University of Singapore",
      date: "Jun'23 - Jul'24",
      professor: "Professor: Mark Goh | Analytics and Operations",
      details: [
        "Built a real-time elder pickup/drop-off tracking attendance system for the Ministry of Health, Singapore.",
        "Optimized ambulance routes using CVRPTWs algorithms, implemented in Knime and .NET.",
        "Developed seating plans based on unloading sequence, elder type, and vehicle configuration.",
        "Created web and mobile apps using Django, React-Native, Leaflet, and Firebase."
      ],
      githubLink: "https://github.com/notabee-git/NUS-eldercare-app.git" // Replace with actual GitHub link
    },
    {
      title: "Data Science Intern | Amazon Data Optimizer | Account Manager Chatbot",
      company: "Adyogi",
      date: "Jun'24 - Jul'24",
      details: [
        "Boosted revenue by 450% and sales by 46% for clients through optimized Amazon product data processing.",
        "Developed an RAG-based chatbot for ad spend optimization on Facebook and Google.",
        "Built APIs in Flask and deployed them on Azure, integrated with company services."
      ],
      githubLink: "https://github.com/notabee-git/adyogi-feedai" // Replace with actual GitHub link
    }
  ];

  return (
    <section id="internships" className="bg-gradient-to-b from-black to-blue-900 min-h-screen flex flex-col justify-center items-center text-center px-4 py-20">
      <h2 className="text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-indigo-400 mb-12">
        Internships
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-6xl">
        {internships.map((internship, index) => (
          <InternshipCard key={index} {...internship} />
        ))}
      </div>
    </section>
  );
}