import React from 'react';

export default function About() {
  return (
    <section id="about" className="bg-gradient-to-b from-blue-900 to-black min-h-screen flex flex-col justify-center items-center text-center px-4 py-20">
      <div className="max-w-3xl">
        <h1 className="text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-blue-600 mb-12">
          About Me
        </h1>
        <p className="text-lg text-gray-200 mb-8">
          I am Abhiram Veladineni, a student at IIT Kharagpur with a keen interest in software development, data science, and cloud technologies. I strive to blend technical skills with creative problem-solving to build innovative solutions.
        </p>
        <p className="text-lg text-gray-200 mb-8">
          My journey includes hands-on experience with modern technologies such as Docker, AWS, and various web frameworks. I am constantly exploring new tools and methodologies to enhance my skills and contribute effectively to projects.
        </p>
        <a href="#contact" className="inline-block bg-gradient-to-r from-purple-600 to-blue-600 text-white font-semibold py-3 px-6 rounded-full hover:bg-gradient-to-l hover:from-blue-600 hover:to-purple-600 transition duration-300 ease-in-out">
          Contact Me
        </a>
      </div>
    </section>
  );
}
