import React from 'react';

const ProjectCard = ({ title, company, date, details, githubLink }) => (
  <div className="bg-gradient-to-br from-blue-900 to-black rounded-lg shadow-lg overflow-hidden transform transition duration-300 hover:scale-105">
    <div className="p-6">
      <h3 className="text-2xl font-bold text-blue-300 mb-2">{title}</h3>
      <p className="italic text-blue-400 mb-1">{company}</p>
      <p className="text-blue-500 mb-2">{date}</p>
      <ul className="list-disc list-inside text-gray-300 space-y-2 mb-4">
        {details.map((detail, index) => (
          <li key={index}>{detail}</li>
        ))}
      </ul>
    </div>
    <div className="bg-blue-800 px-6 py-4">
      <a
        href={githubLink}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-300 hover:text-blue-100 transition duration-300"
      >
        View on GitHub
      </a>
    </div>
  </div>
);

export default function Projects() {
    const projects = [
        {
          title: "Employee Payment and Email Notification System",
          company: "100xDevs | Course Project",
          date: "May'24 - Jun'24",
          details: [
            "Engineered a scalable automation platform for Solana payments and email notifications.",
            "Developed a Next.js interface to create automated workflows based on triggers and actions.",
            "Built an express webhook that triggers end-to-end workflows asynchronously.",
            "Ensured atomicity using Prisma, PostgreSQL, and Kafka."
          ],
          githubLink: "https://github.com/notabee-git/automation-software" // Replace with actual GitHub link
        },
        {
          title: "Crypto Exchange | Pub Subs | Web Sockets",
          company: "100xDevs | Course Project",
          date: "Apr'24 - May'24",
          details: [
            "Built a crypto exchange handling 100,000 concurrent users with 1ms latency using Redis Pub/Subs and WebSockets.",
            "Developed a React.js UI for real-time trading with market selection and live graphs.",
            "Set up CI/CD pipeline with GitHub, Docker, and AWS for automated deployment."
          ],
          githubLink: "https://github.com/notabee-git/crypto-exchange" // Replace with actual GitHub link
        },
        {
          title: "Search Engine | Algozenith Hackathon",
          company: "",
          date: "Jan'24 - Feb'24",
          details: [
            "Developed a text-based search engine using TF-IDF for LeetCode problems.",
            "Designed a scraper using Selenium to gather data from 2400+ problems.",
            "Built a webpage with Flask and HTML to display search results."
          ],
          githubLink: "https://github.com/notabee-git/AZ-TF-IDF-webscraper" // Replace with actual GitHub link
        }
      ];
  return (
    <section id="projects" className="bg-gradient-to-b from-blue-900 to-black min-h-screen flex flex-col justify-center items-center text-center px-4 py-20">
      <h2 className="text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-blue-600 mb-12">
        Projects
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl">
        {projects.map((project, index) => (
          <ProjectCard key={index} {...project} />
        ))}
      </div>
    </section>
  );
}


