import React from 'react';

export default function Navbar() {
  return (
    <nav className="fixed w-full top-0 z-50 bg-black-900 bg-opacity-80 backdrop-blur-sm shadow-lg py-4">
      <div className="max-w-7xl mx-auto px-4 flex justify-between items-center">
        <h1 className="text-2xl font-extrabold text-white">Abhiram Veladineni</h1>
        <ul className="flex space-x-8 text-lg font-semibold text-gray-300">
          <li><a href="#about" className="hover:text-white transition-colors duration-300">About</a></li>
          <li><a href="#internships" className="hover:text-white transition-colors duration-300">Internships</a></li>
          <li><a href="#projects" className="hover:text-white transition-colors duration-300">Projects</a></li>
          <li><a href="#skills" className="hover:text-white transition-colors duration-300">Skills</a></li>
          <li><a href="#coursework" className="hover:text-white transition-colors duration-300">Coursework</a></li>
          <li><a href="#contact" className="hover:text-white transition-colors duration-300">Contact</a></li>
        </ul>
      </div>
    </nav>
  );
}
