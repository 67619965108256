import React from 'react';

const CourseCard = ({ title, courses }) => (
  <div className="bg-gradient-to-br from-blue-900 to-black rounded-lg shadow-lg overflow-hidden transform transition duration-300 hover:scale-105 p-6">
    <h3 className="text-2xl font-bold text-blue-300 mb-4">{title}</h3>
    <ul className="list-none text-lg text-gray-300 space-y-2 leading-relaxed">
      {courses.map((course, index) => (
        <li key={index}>• {course}</li>
      ))}
    </ul>
  </div>
);

export default function Coursework() {
  const courseSets = [
    {
      title: "Computer Science",
      courses: [
        "Programming and Data Structures",
        "Database Management System",
        "Computer Architecture and Operating Systems",
        "Computer Networks"
      ]
    },
    {
        title: "Artificial Intelligence",
        courses: [
            "Machine Learning Foundations and Applications",
            "Deep Learning Foundations and Applications",
            "Big Data Processing",
            "Statistical Foundation for AI and Machine Learning"
        ]
    },
    {
      title: "Mathematics",
      courses: [
        "Probability and Statistics",
        "Linear Algebra, Numerical and Complex Analysis",
        "Advanced Calculus",
        "Theory of Partial Differential Equations"
      ]
    },
    {
      title: "Electrical Engineering",
      courses: [
        "Data Communication",
        "Signals and Systems",
        "Digital Electronic Circuits",
        "Analog Electronic Circuits"
      ]
    }
  ];

  return (
    <section id="coursework" className="bg-gradient-to-b from-blue-900 to-black min-h-screen flex flex-col justify-center items-center text-center px-4 py-20">
      <h2 className="text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-indigo-400 mb-12">
        Coursework
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl">
        {courseSets.map((courseSet, index) => (
          <CourseCard key={index} {...courseSet} />
        ))}
      </div>
    </section>
  );
}