import React from 'react';

export default function Contact() {
  return (
    <section id="contact" className="bg-gradient-to-b from-black to-blue-900 min-h-screen flex flex-col justify-center items-center text-center px-4 py-20">
      <h2 className="text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-600 mb-12">
        Contact
      </h2>
      <div className="max-w-2xl">
        <p className="text-lg text-gray-200 mb-8">
          I’m always open to discussing new opportunities, collaborations, or just connecting with fellow professionals. Feel free to reach out to me on <a href="https://www.linkedin.com/in/abhiram-veladineni-571987223/abhiramveladineni03@gmail.com" className="text-blue-400 underline">Linkedin</a> .
        </p>
      </div>
    </section>
  );
}
